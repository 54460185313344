import React from 'react';
import './App.css';
import Logo from './img/logo_full.svg';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={Logo} alt="Logo" width="50%"/>
        <p style={{color: "#acacac"}}>contact@arkhana.io</p>
      </header>
    </div>
  );
}

export default App;
